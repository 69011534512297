.section-wrapper-deals {
    position: relative;
    
}

.deals-container {
    margin-left: 3em;
    margin-right: 3em;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}


.daily-deals {
    height: auto;
    width: 100%;
}


.small {
    display: none;
}

.smallest {
    display: none;
}


@media (max-width: 800px) {
    .big {
        display: none;
    }
    .small {
        display: block;
    }
}


@media (max-width: 500px) {
    .small {
        display: none;
    }
    .smallest {
        display: block;
    }
}


