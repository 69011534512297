

.fill {
    width: 35%;
}

.tab-header {
    display: flex;
    flex-wrap: wrap;
    margin: 2em 2em 0.5em 2em;
    justify-content: space-between;
}

.section-name {
    font-size: 20px;
    flex: 1;
    max-width: 100%;
}

.tabs-select button {
    background-color: transparent;

    width: 40%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    border: none;
}

.tabs-select button:hover {
    cursor: pointer;
}

.buttons {
    display: flex;
    height: 100%;
    width: 100%;
}


.tabs-select {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    width: 40%;
}

.active{
    font-weight: bold;
    color: rgb(210, 0, 0);;
}

.grey-line {
    background-color: rgb(179, 179, 179);
    width: auto;
    height: 1px;
    margin: 0.5em 2em 0.5em 2em;
}

@media (max-width: 950px){
    .fill {
        width: 100%;
    }  
    .tabs-select button{
        margin-right: 1em;
    }
    .tabs-select {
        margin-top: 0.5em;
    }
}

@media (max-width: 500px){
    .tabs-select button:nth-of-type(5) {
        display: none;
    }
    .tabs-select button{
        margin-right: 2em;
    }
       
}


.s-container {
    position: relative;
    margin-top: 1em;
    margin-bottom: 4rem;
    width: 100%;
}

.s-container .swiper {
    width: 90%;
    height: auto;
    position: unset;
}

.s-container .swiper-slide {
    box-sizing: border-box;
    display: column;
    overflow: hidden;
    position: relative;
}

.s-container .swiper-button-prev {
    left: 1rem;
}

.s-container .swiper-button-next {
    right: 1rem;
}

.s-container .swiper-button-next, .s-container .swiper-button-prev {
    color: black;
}

.img-p {
    width: 100%;
    height: 100%;
    border-radius: 3px;
}

.name {
    font-size: 15px;
    color: rgb(61, 61, 61);
    white-space: nowrap;
    width: 100%;

}
