.footer-wrapper {
    background-color: black;
    
    color: white;
    position: relative;
}

.main-container {
    padding: 3rem 6rem;
    display: flex;
    justify-content: center;
    position: relative;
}

.link-column {
    position: relative;
    margin-right: 1em;
    margin-left: 1em;
}


.column-section {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
    white-space: nowrap;
    
}

.shopping, .other-info, .contact{
    margin-top: 1em;
}



.footer-link-container {
    display: grid;
    
}

.small-link {
    margin-top: 1.5em;
    word-wrap: break-word;
    font-size: 12px;
    color: rgb(163, 163, 163);
    text-decoration: none;
}

.address {
    margin-top: 2em;
    font-size: 12px;
    color: rgb(163, 163, 163);
    display: grid;
}

.address span:nth-of-type(1){
    font-weight: bold;
}



.actions{
    width: 40%;
    display: flex;
    position: relative;
    justify-content: flex-start;
    margin-left: 4rem;
    
}

.news-signup {
    margin-top: 1em;
}

.icon-container {
   
    display: flex;
    justify-content:space-around;
    align-items: center;
    padding: 0.5em;
}


.connect-container {
    
    width: 60%;
    text-transform: uppercase;
    font-weight: bold;
}

.app {
    width: 40%;
}

.phone {
    display: flex;
    justify-content: flex-start;
}


.social-icons {
    color:rgb(163, 163, 163);
    font-size:35px;
    
}



.newsletter {
    margin-top: 1em;
    font-size: 12px;
    font-weight: normal;
    text-transform: none;
    position: relative;
    color: rgb(163, 163, 163);
    padding: 0;
}

.newsletter:hover {
    color: rgb(210, 0, 0);
    cursor: pointer;
}


.mark {
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-bottom: 2em;
}


.leviathan-logo {
    font-size: 18px;
    font-weight: bold;
    color: rgb(230, 0, 0);
}



.copyright {
    margin-top: 1.5em;
    font-size: 13px;
    color: rgb(163, 163, 163);
}


@media (max-width: 975px) {
    .footer-wrapper {
        display: none;
    }
}

