.popular {
    font-weight: bold;
}

.popular-categories {
    margin-bottom: 1em;
}

.separate {
    height: 0.5em;
}

.sub-link {
    font-weight:500;
    color: black;
    text-decoration: underline;
}

