@import url('https://fonts.googleapis.com/css?family=Handel+Gothic+Regular');

* {
    font-family: 'Handel Gothic Regular', sans-serif;
    margin: 0;
    padding: 0; 
    box-sizing: border-box;
}

html{
    height: 100%;
    width: 100%;
}


.body {
    height: 100%;
    width: 100%;
}
