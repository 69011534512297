.pre-footer-wrapper {
    width: 100%;
    margin-top: 5rem;
    display: flex;
    justify-content: center;
    position: relative;
    
}

.pre-footer-box {
    width: 25%;

    border: 0.5px solid rgb(165, 165, 165);
    display: grid;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 1em;
}

.pre-footer-box:hover {
    cursor: pointer;
}

.pre-footer-box .text-box {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.pre-footer-box span:nth-of-type(2){
    padding-top: 0.7em;
    font-size: 16px;
}

.icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2.5em;
    margin-bottom: 1.5em;
}

.icon {
    color: black;
    font-size: 45px;
}

.pre-footer-box .text-box:nth-of-type(1) {
    font-weight: bold;
}

.click {
    text-decoration: none;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    font-size: 13px;
    color: inherit;
}


@media (max-width: 600px) {
    .pre-footer-box span:nth-of-type(2){
        display: none;
    }
    .pre-footer-box .click{
        display: none;
    }
}