.header {
    width: 100%;
    margin: 0;
    padding: 0;
}

.header-top {
    background-color: white;
    display: flex;
    height: 2.5rem;
    width: 100%;
}

.top-logo {
    position: relative;
    margin-left: 3rem;
}

.space {
    flex-grow: 1;
}

.small-logo {
    padding-top: 0.5em;
    position: relative;
    font-size: larger;
    color: rgb(133, 133, 133)
}


.link-container {
    display: flex;
    /*border: 1px solid red;*/
    margin-right: 3rem;
}

.link-wrap {
    /*border: 1px solid green;*/
    font-size: 14px;
    padding-left: 1rem;
    padding-top: 0.75rem;
}

.link-wrap a {
    text-decoration: none;
    color:#666666;
    cursor: pointer;
}




/* Large section of header, black background */
.header-middle {
    background-color: black;
    height: 7rem;
    display: flex;
}

/* Big LEVIATHAN text */
.middle-logo {
    /*border: 1px solid red;*/
    position: relative;
    margin-left: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}


.big-logo {
    font-size: 48px;
    /*padding-top: 2rem;*/
    position: relative;
    color: white;
}

.big-logo:hover {
    color: rgb(210, 0, 0);
}

.search-container {
    position: relative;
    width: 40%;
    display: flex;
    flex-basis: 60%;
    margin-left: 3rem;
    align-items: center;
    justify-content: center;
}

/*This is what has the appearance of the search bar*/
.search-wrapper {
    min-width:100%;
    height: 35%;
    position: relative;
    background-color: white;
    display: flex;
    flex-basis: 50%;
}

.search-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
}

.search-icon {
    color: black;
    font-size: 20px;
}

/*This is the actual search bar tucked inside*/
.search {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    margin-left: 1em;
    font-size: 17px;
}


.search::placeholder {
    color:rgb(94, 94, 94);

    
}


/* This holds onto both our daily shipping deals button */
/* and our text slideshow */
.shipping-deals-container{
    width: 18em;
    margin-left: 2.5em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /*border: 2px solid rgb(136, 77, 255);*/

}

.shipping-deals-button {
    min-width: 75%;
    height: 44%;
    background-color: rgb(234, 2, 2);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5em;
}

.button-container {
    width: 100%;
    height: 50%;
    /*border: 2px solid magenta;*/
}

.deals-text {
    color: white;
    font-size: 12px;
    position: relative;
    text-align: center;

    width: 100%;
}

.text-container {
    /*border: 2px solid rgb(20, 182, 11);*/
    width: 100%;
    height: 50%;
}

.text-slideshow {
    color: white;
    width: 100%;
    height: 50%;
    font-size: 12px;
}


.shipping-deals-text {
    transition: opacity 1s;
    opacity: 1;
    font-size: 13px;
}

.out {
    opacity: 0;
    transition: opacity 1s;
}


.shipping-deals-text-red{
    color: red;
}



.navigation {
    display: flex;
    align-items:flex-start;
    height: 3rem;
    flex-wrap: wrap;
}

.menu-items {
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex: 1;
    font-size: 15px;
    flex-wrap: wrap;
}

ul {
    list-style-type: none;
    background-color: #333333;  
}


.button {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-decoration: none;
    color: white;
    text-transform: uppercase;
    white-space: nowrap;
    padding: 1em;
    font-weight: bold;
}

.button:hover {
    color:rgb(210, 0, 0)
}

.red {
    background-color: rgb(210, 0, 0);
}

.red:hover {
    color: white;
}

.drop-icon {
    font-size: 25px;
}


.icons-right {
    height: 50%;
    margin-top: 2em;
    min-width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
}



.login-pic {
    color: white;
    font-size: 20px;
}

.shopping-cart {
    color: white;
    font-size: 20px;
    margin-left: 1rem;
}

.icons-right {
    margin-left: 1em;
}

.icons-right span {
    color: white;
    margin-left: 0.5em;
    white-space: nowrap;
}


@media (max-width: 1300px){
    .menu-items:nth-of-type(6){
        display: none;
    }
}

@media (max-width: 1140px){
    .menu-items:nth-of-type(9){
        display: none;
    }
}

@media (max-width: 900px){
    .menu-items:nth-of-type(3){
        display: none;
    }
}

@media (max-width: 790px){
    .menu-items:nth-of-type(5){
        display: none;
    }
}

@media (max-width: 700px){
    .menu-items:nth-of-type(2){
        display: none;
    }
    .middle-logo {
        margin-left: 1rem;
    }
}

@media (max-width: 550px){
    .icons-right{
        display: none;
    }
    .big-logo {
        font-size: 40px;
    }
    .search-container {
        margin-left:1rem;
    }
}

@media (max-width: 1025px) {
    .shipping-deals-container {
        display: none;
    }
    .header-top {
        display: none;
    }
    .header-middle {
        height: 50%;
    }
    .search-wrapper {
        border: 2px solid white;
        height: 2rem;
    }
    .icons-right {
        margin-bottom: 2rem;
    }
    .icons-right span {
        display: none;
    }
    .menu-items:nth-of-type(4){
        display: none;
    }
}

@media (max-width: 460px){
    .menu-items:nth-of-type(7){
        display: none;
    }
}

@media (max-width: 380px){
    .menu-items:nth-of-type(11){
        display: none;
    }
    .search-container{
        display: none;
    }
}